@tailwind base;
@tailwind components;
@tailwind utilities;

article {
    p {
        box-sizing: border-box;
        color: rgb(67, 82, 110);
        display: block;
        font-family: RMNeue, arial, helvetica;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 32px;
        margin-block-end: 20px;
        margin-block-start: 20px;
        margin-inline-end: 0px;
        margin-inline-start: 0px;
        text-size-adjust: 100%;
        unicode-bidi: isolate;
    }

    h2 {
        box-sizing: border-box;
        color: rgb(67, 82, 110);
        display: block;
        font-family: RMNeue, arial, helvetica;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: normal;
        line-height: 32px;
        margin-block-end: 20px;
        margin-block-start: 20px;
        margin-inline-end: 0px;
        margin-inline-start: 0px;
        text-size-adjust: 100%;
        unicode-bidi: isolate;
    }
}
